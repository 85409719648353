// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogPostCategoryNavbar from "../navbar/BlogPostCategoryNavbar.res.js";
import * as BlogPostCategoryNewForm from "./BlogPostCategoryNewForm.res.js";
import * as BlogPostCategoryNewScss from "./BlogPostCategoryNew.scss";

var css = BlogPostCategoryNewScss;

function BlogPostCategoryNew$default(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(BlogPostCategoryNavbar.make, {
                      selectedCategory: "NewBlogPostCategory"
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.container,
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: css.pageTitle,
                              children: "New Blog Post Category"
                            }),
                        JsxRuntime.jsx(BlogPostCategoryNewForm.make, {})
                      ]
                    })
              ],
              className: css.pageContainer
            });
}

var $$default = BlogPostCategoryNew$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */

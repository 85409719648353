// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Col from "../../../styleguide/components/Grid/Col/Col.res.js";
import * as Row from "../../../styleguide/components/Grid/Row/Row.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Grid from "../../../styleguide/components/Grid/Grid.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as Quill from "../../../bindings/Quill.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Editor from "../../../styleguide/forms/Editor/Editor.res.js";
import * as Select from "../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Textarea from "../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ErrorMessage from "../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_BlogPostCategory from "../../../api/blog-post-categories/Api_BlogPostCategory.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as Routes_BlogPostCategory from "../../../routes/common/Routes_BlogPostCategory.res.js";
import * as BlogPostCategoryNewFormScss from "./BlogPostCategoryNewForm.scss";

var css = BlogPostCategoryNewFormScss;

var validators_priorityOrder = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var number = input.priorityOrder;
      if (number < 0) {
        return {
                TAG: "Error",
                _0: "Priority order must be 0 or greater"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.priorityOrder
              };
      }
    })
};

var validators_featured = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.featured;
      if (match === "") {
        return {
                TAG: "Error",
                _0: "Featured is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.featured
              };
      }
    })
};

var validators_metaDescription = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.metaDescription.length;
      if (length !== 0) {
        if (length < 40) {
          return {
                  TAG: "Error",
                  _0: "Meta description must be more than 40 characters."
                };
        } else if (length > 160) {
          return {
                  TAG: "Error",
                  _0: "Meta description must no more than 160 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.metaDescription
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Meta description is required"
              };
      }
    })
};

var validators_metaTitle = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.metaTitle.length;
      if (length !== 0) {
        if (length < 20) {
          return {
                  TAG: "Error",
                  _0: "Meta title must be more than 20 characters."
                };
        } else if (length > 155) {
          return {
                  TAG: "Error",
                  _0: "Meta title must no more than 155 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.metaTitle
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Meta title is required"
              };
      }
    })
};

var validators_title = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.title.length;
      if (length !== 0) {
        if (length < 3) {
          return {
                  TAG: "Error",
                  _0: "Title must be more than 3 characters."
                };
        } else if (length > 30) {
          return {
                  TAG: "Error",
                  _0: "Title must be no more than 30 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.title
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Title is required."
              };
      }
    })
};

var validators = {
  priorityOrder: validators_priorityOrder,
  featured: validators_featured,
  metaDescription: validators_metaDescription,
  metaTitle: validators_metaTitle,
  title: validators_title
};

function initialFieldsStatuses(_input) {
  return {
          priorityOrder: "Pristine",
          featured: "Pristine",
          metaDescription: "Pristine",
          metaTitle: "Pristine",
          title: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.priorityOrder;
  var tmp;
  tmp = typeof match !== "object" ? validators.priorityOrder.validate(input) : match._0;
  var match$1 = fieldsStatuses.featured;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.featured.validate(input) : match$1._0;
  var match$2 = fieldsStatuses.metaDescription;
  var tmp$2;
  tmp$2 = typeof match$2 !== "object" ? validators.metaDescription.validate(input) : match$2._0;
  var match$3 = fieldsStatuses.metaTitle;
  var tmp$3;
  tmp$3 = typeof match$3 !== "object" ? validators.metaTitle.validate(input) : match$3._0;
  var match$4 = fieldsStatuses.title;
  var tmp$4;
  tmp$4 = typeof match$4 !== "object" ? validators.title.validate(input) : match$4._0;
  var priorityOrderResult = tmp;
  var priorityOrderResult$1;
  if (priorityOrderResult.TAG === "Ok") {
    var featuredResult = tmp$1;
    if (featuredResult.TAG === "Ok") {
      var metaDescriptionResult = tmp$2;
      if (metaDescriptionResult.TAG === "Ok") {
        var metaTitleResult = tmp$3;
        if (metaTitleResult.TAG === "Ok") {
          var titleResult = tmp$4;
          if (titleResult.TAG === "Ok") {
            return {
                    TAG: "Valid",
                    output: {
                      title: titleResult._0,
                      metaTitle: metaTitleResult._0,
                      metaDescription: metaDescriptionResult._0,
                      featured: featuredResult._0,
                      priorityOrder: priorityOrderResult._0
                    },
                    fieldsStatuses: {
                      priorityOrder: {
                        TAG: "Dirty",
                        _0: priorityOrderResult,
                        _1: "Shown"
                      },
                      featured: {
                        TAG: "Dirty",
                        _0: featuredResult,
                        _1: "Shown"
                      },
                      metaDescription: {
                        TAG: "Dirty",
                        _0: metaDescriptionResult,
                        _1: "Shown"
                      },
                      metaTitle: {
                        TAG: "Dirty",
                        _0: metaTitleResult,
                        _1: "Shown"
                      },
                      title: {
                        TAG: "Dirty",
                        _0: titleResult,
                        _1: "Shown"
                      }
                    },
                    collectionsStatuses: undefined
                  };
          }
          priorityOrderResult$1 = priorityOrderResult;
        } else {
          priorityOrderResult$1 = priorityOrderResult;
        }
      } else {
        priorityOrderResult$1 = priorityOrderResult;
      }
    } else {
      priorityOrderResult$1 = priorityOrderResult;
    }
  } else {
    priorityOrderResult$1 = priorityOrderResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            priorityOrder: {
              TAG: "Dirty",
              _0: priorityOrderResult$1,
              _1: "Shown"
            },
            featured: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            metaDescription: {
              TAG: "Dirty",
              _0: tmp$2,
              _1: "Shown"
            },
            metaTitle: {
              TAG: "Dirty",
              _0: tmp$3,
              _1: "Shown"
            },
            title: {
              TAG: "Dirty",
              _0: tmp$4,
              _1: "Shown"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurPriorityOrderField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.priorityOrder, validators_priorityOrder, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  priorityOrder: status,
                                  featured: init.featured,
                                  metaDescription: init.metaDescription,
                                  metaTitle: init.metaTitle,
                                  title: init.title
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurFeaturedField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.featured, validators_featured, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  priorityOrder: init.priorityOrder,
                                  featured: status,
                                  metaDescription: init.metaDescription,
                                  metaTitle: init.metaTitle,
                                  title: init.title
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaDescriptionField" :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.metaDescription, validators_metaDescription, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  priorityOrder: init.priorityOrder,
                                  featured: init.featured,
                                  metaDescription: status,
                                  metaTitle: init.metaTitle,
                                  title: init.title
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaTitleField" :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.metaTitle, validators_metaTitle, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  priorityOrder: init.priorityOrder,
                                  featured: init.featured,
                                  metaDescription: init.metaDescription,
                                  metaTitle: status,
                                  title: init.title
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurTitleField" :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.title, validators_title, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  priorityOrder: init.priorityOrder,
                                  featured: init.featured,
                                  metaDescription: init.metaDescription,
                                  metaTitle: init.metaTitle,
                                  title: status
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdatePriorityOrderField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.priorityOrder, state.submissionStatus, validators_priorityOrder, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            priorityOrder: status,
                                            featured: init.featured,
                                            metaDescription: init.metaDescription,
                                            metaTitle: init.metaTitle,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateFeaturedField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.featured, state.submissionStatus, validators_featured, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            priorityOrder: init.priorityOrder,
                                            featured: status,
                                            metaDescription: init.metaDescription,
                                            metaTitle: init.metaTitle,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaDescriptionField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.metaDescription, state.submissionStatus, validators_metaDescription, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            priorityOrder: init.priorityOrder,
                                            featured: init.featured,
                                            metaDescription: status,
                                            metaTitle: init.metaTitle,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaTitleField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.metaTitle, state.submissionStatus, validators_metaTitle, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            priorityOrder: init.priorityOrder,
                                            featured: init.featured,
                                            metaDescription: init.metaDescription,
                                            metaTitle: status,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateTitleField" :
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.title, state.submissionStatus, validators_title, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            priorityOrder: init.priorityOrder,
                                            featured: init.featured,
                                            metaDescription: init.metaDescription,
                                            metaTitle: init.metaTitle,
                                            title: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updatePriorityOrder: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdatePriorityOrderField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateFeatured: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateFeaturedField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaDescription: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaTitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaTitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateTitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateTitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurPriorityOrder: (function () {
              dispatch("BlurPriorityOrderField");
            }),
          blurFeatured: (function () {
              dispatch("BlurFeaturedField");
            }),
          blurMetaDescription: (function () {
              dispatch("BlurMetaDescriptionField");
            }),
          blurMetaTitle: (function () {
              dispatch("BlurMetaTitleField");
            }),
          blurTitle: (function () {
              dispatch("BlurTitleField");
            }),
          priorityOrderResult: Formality.exposeFieldResult(state.fieldsStatuses.priorityOrder),
          featuredResult: Formality.exposeFieldResult(state.fieldsStatuses.featured),
          metaDescriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.metaDescription),
          metaTitleResult: Formality.exposeFieldResult(state.fieldsStatuses.metaTitle),
          titleResult: Formality.exposeFieldResult(state.fieldsStatuses.title),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.priorityOrder;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.featured;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.metaDescription;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.metaTitle;
              if (typeof tmp$3 === "object") {
                return true;
              }
              var tmp$4 = match.title;
              if (typeof tmp$4 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var BlogPostCategoryForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function reducer(state, action) {
  return {
          editorState: Editor.State.compose(state.editorState, action._0)
        };
}

function BlogPostCategoryNewForm(props) {
  var initialState = {
    editorState: Editor.State.make()
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var initialInput = React.useMemo((function () {
          return {
                  title: "",
                  metaTitle: "",
                  metaDescription: "",
                  featured: "False",
                  priorityOrder: 100
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_BlogPostCategory.create(output.title, document.querySelector(".ql-editor").innerHTML, output.metaTitle, output.metaDescription, output.featured, output.priorityOrder), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_BlogPostCategory.Dashboard.edit(x._0.id));
                  } else {
                    return SentryLogger.error1({
                                rootModule: "BlogPostCategoryNewForm",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "BlogPostCategoryNewForm.make"
                              }, "CreateNewBlogPostCategory::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  className: css.error,
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var containerRef = React.useRef(null);
  var editorRef = React.useRef(undefined);
  React.useEffect((function () {
          var editor = Belt_Option.getExn(Belt_Option.map(Caml_option.nullable_to_opt(containerRef.current), (function (container) {
                      return Quill.make("Type here...", undefined, undefined, Quill.blogPostCategoryToolbarOptions, {}, undefined, container);
                    })));
          var listener = function (delta, param) {
            dispatch({
                  TAG: "SetEditorState",
                  _0: delta
                });
          };
          editorRef.current = Caml_option.some(editor);
          editor.on("text-change", listener);
          return (function () {
                    editor.off("text-change", listener);
                  });
        }), []);
  var match$1 = form.status;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "SubmissionFailed" ? null : JsxRuntime.jsxs(Row.make, {
          className: "row",
          children: [
            JsxRuntime.jsx(Col.make, {
                  className: css.buttonHiddenContainer,
                  md: 3,
                  lg: 3,
                  children: JsxRuntime.jsx("div", {})
                }),
            JsxRuntime.jsx(Col.make, {
                  className: css.buttonHiddenContainer,
                  md: 9,
                  lg: 9,
                  children: JsxRuntime.jsx(ErrorMessage.make, {
                        className: css.error,
                        children: "Something went wrong."
                      })
                })
          ]
        });
  return JsxRuntime.jsx("form", {
              children: JsxRuntime.jsxs(Grid.make, {
                    className: css.grid,
                    children: [
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-category-form--title",
                                          children: "Title"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "blog-post-category-form--title",
                                            value: form.input.title,
                                            placeholder: "Add a title for your new category.",
                                            onChange: (function ($$event) {
                                                form.updateTitle((function (input, value) {
                                                        return {
                                                                title: value,
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: input.metaDescription,
                                                                featured: input.featured,
                                                                priorityOrder: input.priorityOrder
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.titleResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-category-form--description",
                                          children: "Description"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(Editor.make, {
                                                  ref: containerRef
                                                }),
                                            className: css.editor
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "Note: The description is used to populate the category summary on the blog post page.",
                                            className: css.fieldInstructions
                                          })
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-category-form--meta-title",
                                          children: "Meta Title"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "blog-post-category-form--meta-title",
                                            value: form.input.metaTitle,
                                            placeholder: "Add a meta title.",
                                            onChange: (function ($$event) {
                                                form.updateMetaTitle((function (input, value) {
                                                        return {
                                                                title: input.title,
                                                                metaTitle: value,
                                                                metaDescription: input.metaDescription,
                                                                featured: input.featured,
                                                                priorityOrder: input.priorityOrder
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.metaTitleResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-category-form--meta-description",
                                          children: "Meta Description"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(Textarea.make, {
                                            id: "blog-post-category-form--meta-description",
                                            value: form.input.metaDescription,
                                            placeholder: "Add a meta description.",
                                            onChange: (function ($$event) {
                                                form.updateMetaDescription((function (input, value) {
                                                        return {
                                                                title: input.title,
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: value,
                                                                featured: input.featured,
                                                                priorityOrder: input.priorityOrder
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.metaDescriptionResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-category-form--featured",
                                          children: "Featured"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsxs(Select.make, {
                                            id: "blog-post-category-form--popular",
                                            value: form.input.featured,
                                            onChange: (function ($$event) {
                                                form.updateFeatured((function (input, value) {
                                                        return {
                                                                title: input.title,
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: input.metaDescription,
                                                                featured: value,
                                                                priorityOrder: input.priorityOrder
                                                              };
                                                      }), $$event.target.value);
                                              }),
                                            children: [
                                              JsxRuntime.jsx(Select.$$Option.make, {
                                                    value: "false",
                                                    children: "False"
                                                  }, "featured-false"),
                                              JsxRuntime.jsx(Select.$$Option.make, {
                                                    value: "true",
                                                    children: "True"
                                                  }, "featured-true")
                                            ]
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "Note: Featured determines if the category appears in the blog subnavigation.",
                                            className: css.fieldInstructions
                                          })
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-category-form--active",
                                          children: "Priority Order"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "blog-post-category-form--priority-order",
                                            value: String(form.input.priorityOrder),
                                            placeholder: "0",
                                            onChange: (function ($$event) {
                                                form.updatePriorityOrder((function (input, value) {
                                                        return {
                                                                title: input.title,
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: input.metaDescription,
                                                                featured: input.featured,
                                                                priorityOrder: value
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.priorityOrderResult),
                                      JsxRuntime.jsx("div", {
                                            children: "Note: The priority sets the order of categories in the blog subnavigation. 0 is the highest priority.",
                                            className: css.fieldInstructions
                                          })
                                    ]
                                  })
                            ]
                          }),
                      tmp,
                      JsxRuntime.jsxs(Row.make, {
                            className: "row",
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonHiddenContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx("div", {})
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonCreateContainer,
                                    md: 4,
                                    lg: 4,
                                    children: JsxRuntime.jsx(Button.make, {
                                          size: "LG",
                                          color: "Primary",
                                          expanded: true,
                                          disabled: form.submitting,
                                          busy: form.submitting,
                                          submit: true,
                                          children: "Create Blog Post Category"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonCancelContainer,
                                    md: 5,
                                    lg: 5,
                                    children: JsxRuntime.jsx(Button.make, {
                                          size: "LG",
                                          color: "White",
                                          expanded: true,
                                          busy: false,
                                          submit: true,
                                          onClick: (function (e) {
                                              e.preventDefault();
                                              Url.visit(Routes_BlogPostCategory.Dashboard.index);
                                            }),
                                          children: "Cancel"
                                        })
                                  })
                            ]
                          })
                    ]
                  }),
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var make = BlogPostCategoryNewForm;

export {
  css ,
  BlogPostCategoryForm ,
  reducer ,
  make ,
}
/* css Not a pure module */
